import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import Select from "react-select";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

//import hooks
import useOutsideClick from "../../hooks/useOutsideClick";

export default function Register() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [centerLocation, setCenterLocation] = useState("");
  const [aboNum, setAboNum] = useState("");
  const [upline, setUpline] = useState("");
  const [uplinePhoneNum, setUplinePhoneNum] = useState("");
  const [platform, setPlatform] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNumError, setPhoneNumError] = useState(null);
  const [uplinePhoneNumError, setUplinePhoneNumError] = useState(null);
  const [platformError, setPlatformError] = useState(null);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const options = [
    { value: "alor-setar", label: "Alor Setar" },
    { value: "ampang", label: "Ampang" },
    { value: "bangi", label: "Bangi" },
    { value: "bintulu", label: "Bintulu" },
    { value: "brunei", label: "Brunei" },
    { value: "butterworth", label: "Butterworth" },
    { value: "damansara", label: "Damansara" },
    { value: "ipoh", label: "Ipoh" },
    { value: "johor-bahru", label: "Johor Bahru" },
    { value: "kota-kinabalu", label: "Kota Kinabalu" },
    { value: "kuantan", label: "Kuantan" },
    { value: "kuching-kings", label: "Kuching (King's Center)" },
    { value: "kuching-star", label: "Kuching (Star Point)" },
    { value: "melaka", label: "Melaka" },
    { value: "miri", label: "Miri" },
    { value: "penang", label: "Penang" },
    { value: "sandakan", label: "Sandakan" },
    { value: "sg-petani", label: "Sg Petani" },
    { value: "singapore", label: "Singapore" },
    { value: "tawau", label: "Tawau" },
  ];

  const customStyles = {
    control: (provided, state) => {
      const borderStyle = state.isFocused
        ? "1px solid rgba(104,109,224)"
        : "none";
      const boxShadowStyle = state.isFocused
        ? "0px 0px 0px 4px rgba(104,109,224,0.2);"
        : "none";

      return {
        ...provided,
        borderRadius: "6px",
        height: "40px",
        backgroundColor: "#f1f2f6",
        border: "none",
        border: borderStyle,
        boxShadow: boxShadowStyle,
      };
    },
  };

  useEffect(() => {
    // check to see if we have a localstora
    let hasVisited = localStorage.getItem("visited");
    if (hasVisited) {
      let expireDate = hasVisited.expiredAt;
      if (Date.now() > expireDate) {
        setShowModal(true);
        let hasVisited = localStorage.removeItem("visited");
      } else {
        setShowModal(false);
      }
    } else {
      setShowModal(true);
      let now = new Date();
      localStorage.setItem(
        "visited",
        JSON.stringify({
          value: true,
          expiredAt: now.setDate(now.getDate() + 30),
        })
      );
    }
  }, []);

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  function formatPhoneNumber(phoneNumber) {
    try {
      let formattedNumber;

      let firstThreeDigit = phoneNumber.substring(0, 3);

      if (firstThreeDigit.includes("60")) {
        if (phoneNumber.length >= 10 && phoneNumber.length <= 14) {
          formattedNumber = `+${phoneNumber}`;
        } else {
          throw "MY:INVALID";
        }
      } else if (firstThreeDigit.includes("65")) {
        if (phoneNumber.length >= 8 && phoneNumber.length <= 11) {
          formattedNumber = `+${phoneNumber}`;
        } else {
          throw "SG:INVALID";
        }
      } else {
        if (
          phoneNumber.length <= 8 &&
          (phoneNumber.substring(0, 1).includes("3") ||
            phoneNumber.substring(0, 1).includes("6") ||
            phoneNumber.substring(0, 1).includes("8") ||
            phoneNumber.substring(0, 1).includes("9"))
        ) {
          formattedNumber = `+65${phoneNumber}`;
        } else if (
          phoneNumber.substring(0, 2).includes("01") &&
          phoneNumber.length >= 10 &&
          phoneNumber.length <= 11
        ) {
          formattedNumber = `+60${phoneNumber.substring(
            1,
            phoneNumber.length
          )}`;
        } else {
          throw "INVALID";
        }
      }

      return formattedNumber;
    } catch (e) {
      throw e;
    }
  }

  const handlePhoneNumChange = (e) => {
    let phoneNumber = e.target.value.replace(/\D/g, "");

    try {
      formatPhoneNumber(phoneNumber);
      setPhoneNumError(null);
    } catch (e) {
      setPhoneNumError("Please enter a valid phone number");
    }

    setPhoneNum(phoneNumber);
  };

  const handleUplinePhoneNumChange = (e) => {
    let phoneNumber = e.target.value.replace(/\D/g, "");

    try {
      formatPhoneNumber(phoneNumber);
      setUplinePhoneNumError(null);
    } catch (e) {
      setUplinePhoneNumError("Please enter a valid phone number");
    }

    setUplinePhoneNum(phoneNumber);
  };

  const handleLocationChange = (selectedOption) => {
    setCenterLocation(selectedOption.value);
  };

  const handleAboNumChange = (e) => {
    setAboNum(e.target.value);
  };

  const handleUplineChange = (e) => {
    setUpline(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!platform) {
      setPlatformError(true);

      setTimeout(() => {
        setPlatformError(null);
      }, 500);
      return;
    }

    if (
      fullName === "" ||
      email === "" ||
      phoneNum === "" ||
      centerLocation === "" ||
      aboNum === "" ||
      upline === "" ||
      uplinePhoneNum === "" ||
      platform === "" ||
      phoneNumError != null ||
      uplinePhoneNumError != null ||
      platformError != null
    ) {
      // console.log("There is empty string");
    } else {
      try {
        setLoading(true);
        let formattedPhoneNum = formatPhoneNumber(phoneNum);
        let formattedUplinePhoneNum = formatPhoneNumber(uplinePhoneNum);
        // get payment url
        let paymentRes = await axios.post(
          "https://us-central1-ecvi-eva.cloudfunctions.net/api/new-transaction",
          {
            name: fullName,
            amount: 100,
            desc: "New EVA User",
            email: email,
            mobile: formattedPhoneNum,
          }
        );
        let registerRes = await axios.post(
          "https://us-central1-ecvi-eva.cloudfunctions.net/api/register",
          {
            fullName,
            email,
            phoneNum: formattedPhoneNum,
            centerLocation,
            aboNum,
            upline,
            uplinePhoneNum: formattedUplinePhoneNum,
            platform,
            billID: paymentRes.data.id,
            amount: paymentRes.data.amount,
            status: "not-paid",
          }
        );
        localStorage.setItem("billId", paymentRes.data.id);
        localStorage.setItem("isRenew", false);
        window.location.replace(
          paymentRes.status == 200 && paymentRes.data.url
        );
        sessionStorage.setItem("pendingId", registerRes.data.id);
        // history.push(
        //   `/payment?fullName=${fullName}&email=${email}&phoneNum=${phoneNum}&centerLocation=${centerLocation}&aboNum=${aboNum}&upline=${upline}&platform=${platform}`
        // );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleShowModal = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <div className={styles["register-page"]}>
      <div className={styles["container"]}>
        <main>
          <div className={styles["registration-form-header"]}>
            <img className={styles["logo"]} src="/ecvi-logo.png" />
            <h2>Sign up for EVA</h2>
            <p>
              If you already have an account,{" "}
              <Link
                to="/renew"
                style={{
                  textDecoration: "none",
                  color: "red",
                }}
              >
                Click here to renew.
              </Link>
            </p>
          </div>

          <form
            action=""
            method="get"
            className={styles["registration-form"]}
            onSubmit={handleSubmit}
          >
            <div className={styles["form-row"]}>
              <label>Choose your Platform</label>
              <div className={styles["platform-btn"]}>
                <button
                  type="button"
                  className={`${styles["ios-btn"]} ${
                    platform === "ios" ? styles["btn-selected"] : ""
                  } ${platformError ? styles["toggle-right"] : ""}`}
                  onClick={() => setPlatform("ios")}
                >
                  <img src="/ios_logo.png" />
                  <span>iOS</span>
                </button>

                <button
                  type="button"
                  className={`${styles["android-btn"]} ${
                    platform === "android" ? styles["btn-selected"] : ""
                  }
                                ${platformError ? styles["toggle-left"] : ""}
                                `}
                  onClick={() => setPlatform("android")}
                >
                  <img src="/android_logo.png" />
                  <span>Android</span>
                </button>

                <button
                  type="button"
                  className={`${styles["android-btn"]} ${
                    platform === "huawei" ? styles["btn-selected"] : ""
                  }
                                ${platformError ? styles["toggle-left"] : ""}
                                `}
                  onClick={() => setPlatform("huawei")}
                >
                  <img src="/huawei_logo.png" />
                  <span>Huawei</span>
                </button>
              </div>
            </div>

            {platform !== "huawei" ? (
              <div>
                <div className={styles["form-row"]}>
                  <label htmlFor="full-name">Full Name</label>
                  <input
                    type="text"
                    name="full-name"
                    className={styles["registration-form-input"]}
                    id="full-name"
                    value={fullName}
                    onChange={handleNameChange}
                    required
                  ></input>
                </div>

                <div className={styles["form-row"]}>
                  <label htmlFor="email-address">Email Address</label>
                  <input
                    type="email"
                    name="email-address"
                    className={styles["registration-form-input"]}
                    id="email-address"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  ></input>
                </div>

                <div className={styles["form-row"]}>
                  <label htmlFor="phone-number">Phone Number</label>
                  <input
                    type="tel"
                    name="phone-number"
                    className={styles["registration-form-input"]}
                    id="phone-number"
                    value={phoneNum}
                    onChange={handlePhoneNumChange}
                    required
                  ></input>
                  <span
                    style={{ color: "red", marginTop: "6px", fontSize: "14px" }}
                  >
                    {phoneNum.length > 0 && phoneNumError && phoneNumError}
                  </span>
                </div>

                <div className={styles["form-row"]}>
                  <label htmlFor="location">Center Location</label>
                  <>
                    <Select
                      styles={customStyles}
                      options={options}
                      onChange={handleLocationChange}
                    />
                    <input
                      tabIndex={-1}
                      autoComplete="off"
                      style={{ opacity: 0, height: 0 }}
                      value={centerLocation}
                      onChange={() => {}}
                      required
                    />
                  </>
                </div>

                <div className={styles["form-row"]}>
                  <label htmlFor="abo-number">ABO Number</label>
                  <input
                    type="tel"
                    name="abo-number"
                    className={styles["registration-form-input"]}
                    id="abo-number"
                    value={aboNum}
                    onChange={handleAboNumChange}
                    required
                  ></input>
                </div>

                <div className={styles["form-row"]}>
                  <label htmlFor="upline-dd">Upline DD Fullname</label>
                  <input
                    type="text"
                    name="upline-dd"
                    className={styles["registration-form-input"]}
                    id="upline-dd"
                    value={upline}
                    onChange={handleUplineChange}
                    required
                  ></input>
                </div>

                <div className={styles["form-row"]}>
                  <label htmlFor="phone-number">Upline DD Phone Number</label>
                  <input
                    type="tel"
                    name="upline-dd-phone-number"
                    className={styles["registration-form-input"]}
                    id="upline-dd-phone-number"
                    value={uplinePhoneNum}
                    onChange={handleUplinePhoneNumChange}
                    required
                  ></input>
                  <span
                    style={{ color: "red", marginTop: "6px", fontSize: "14px" }}
                  >
                    {uplinePhoneNum.length > 0 &&
                      uplinePhoneNumError &&
                      uplinePhoneNumError}
                  </span>
                </div>

                <div className={styles["btn-row"]}>
                  <button
                    disabled={loading}
                    className={`${styles["submit-btn"]} ${
                      loading ? styles["disabled-btn"] : ""
                    }`}
                  >
                    {!loading ? "Make Payment" : "Processing..."}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className={`${styles["huawei-notice"]}`}>
                  Please be reminded that All Huawei devices DOES NOT support
                  EVA due to the US Sanction. Thank You.
                </p>
              </div>
            )}
          </form>
        </main>
      </div>
      <LoginModal
        show={showModal}
        onShow={handleShowModal}
        onOutsideClick={handleShowModal}
      >
        <div>
          <h3>New to EVA?</h3>
          <div className={styles["action-container"]}>
            <button
              className={styles["renew-btn"]}
              onClick={() => {
                history.push("/login");
              }}
            >
              Nope, I want to Renew
            </button>
            <button
              className={styles["register-btn"]}
              onClick={handleShowModal}
            >
              Yes, I want to Register
            </button>
          </div>
        </div>
      </LoginModal>
    </div>
  );
}

const LoginModal = ({ show, onShow, onOutsideClick, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const handleClick = (e) => {
    if (modalRef.current == e.target) {
      onOutsideClick();
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={styles["modal-container"]}
          ref={modalRef}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <motion.div
            className={styles["modal"]}
            initial={{
              y: "100px",
            }}
            animate={{
              y: "0",
            }}
            exit={{
              y: "100px",
            }}
            transition={{
              type: "tween",
              duration: 0.2,
            }}
          >
            <div className={styles["modal-content"]}>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
