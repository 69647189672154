import React, { useContext, useState, useRef } from "react";
import FirebaseContext from "../../contexts/FirebaseContext";
import moment from "moment";
import styles from "./styles.module.css";
import More from "../../icons/More";
import useOutsideClick from "../../hooks/useOutsideClick";
import Firebase from "../Firebase";

// headers is an array
export default function Table({ headers, data }) {
  let firebase = useContext(FirebaseContext);
  let filteredData = [
    ...data
      .filter((_d) => _d.status == "paid")
      .sort((a, b) => a.timestamp - b.timestamp),
    ...data
      .filter((_d) => _d.status == "not-paid")
      .sort((a, b) => a.timestamp - b.timestamp),
  ];

  const handleApprove = async (user) => {
    if (window.confirm("Please confirm your action")) {
      let result = await firebase.approvePendingUser(user);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Please confirm your action")) {
      let result = await firebase.deletePendingUser(id);
    }
  };

  return (
    <table style={{ marginBottom: "100px" }}>
      <thead>
        <tr>
          {headers.map((header) => {
            return <th className={header.className}>{header.value}</th>;
          })}
        </tr>
      </thead>

      <tbody>
        {filteredData.map((row) => {
          let rowLength = Object.keys(row).length;
          return (
            <tr
              key={row.id}
              className={
                row.status == "paid" ? styles["paid-row"] : styles["unpaid-row"]
              }
            >
              {headers.map((header) => {
                if (header.className == "date") {
                  return (
                    <td className={header.className}>
                      {moment.unix(row.timestamp / 1000).format("DD/MM/YYYY")}
                    </td>
                  );
                } else if (header.className == "status") {
                  return (
                    <td className={header.className}>
                      {row.status == "paid" ? "Paid" : "Not Paid"}
                    </td>
                  );
                } else if (header.className == "actions") {
                  return (
                    <td className={header.className} key={header.className}>
                      <MoreButton
                        onDelete={() => handleDelete(row.id)}
                        onApprove={() => handleApprove(row)}
                      />
                    </td>
                  );
                }

                return (
                  <td className={header.className}>{row[header.className]}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const MoreButton = ({ onDelete, onApprove }) => {
  let [moreButtonClicked, setMoreButtonClick] = useState(false);
  let moreButtonRef = useRef();

  useOutsideClick(moreButtonRef, () => {
    setMoreButtonClick(false);
  });

  return (
    <button
      className={styles["action-btn"]}
      onClick={() => {
        setMoreButtonClick(!moreButtonClicked);
      }}
      ref={moreButtonRef}
    >
      <More />
      <div
        className={styles["action-btn-container"]}
        style={{
          display: moreButtonClicked ? "block" : "none",
        }}
      >
        <button onClick={onApprove}>Approve</button>
        <button style={{ color: "red" }} onClick={onDelete}>
          Delete
        </button>
      </div>
    </button>
  );
};
