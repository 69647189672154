import { auth } from "firebase";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthUserContext } from "../../contexts/AuthUserContext";

function ProtectedRoute({ adminOnly, children, ...rest }) {
  let { authenticated, isAdmin } = useContext(AuthUserContext);
  let authorize = adminOnly == isAdmin;

  console.log(
    `authenticated: ${authenticated}, isAdmin: ${isAdmin}, adminOnly: ${adminOnly}, authorize: ${authorize}`
  );

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!authenticated && !!authorize ? (
          children
        ) : adminOnly ? (
          <Redirect
            to={{
              pathname: "/clogin",
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
