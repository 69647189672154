import React, { useState, useEffect } from "react";
import firebase from "firebase";
import app from "firebase/app";
import "firebase/auth";
import FirebaseContext from "../../contexts/FirebaseContext";
import moment from "moment";

const config = {
  apiKey: "AIzaSyB8O_rxLQxitDWHfSfl9qEY2Ejmlc2rXa4",
  authDomain: "ecvi-eva.firebaseapp.com",
  databaseURL: "https://ecvi-eva.firebaseio.com",
  projectId: "ecvi-eva",
  storageBucket: "ecvi-eva.appspot.com",
  messagingSenderId: "204696522466",
  appId: "1:204696522466:web:02fb169f2179328c6158bb",
  measurementId: "G-PKSXM5VDKQ",
};

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(config);
    this.auth = firebase.auth;
    this.db = firebase.firestore(this.app);
    this.storage = firebase.storage();
  }

  // *** Auth API ***
  login = (email, password) => {
    return this.auth().signInWithEmailAndPassword(email, password);
  };

  logout = () => {
    return this.auth().signOut();
  };

  checkUserExists = async (phoneNumber) => {
    try {
      let snapshot = await this.db
        .collection("users")
        .where("phoneNumber", "==", phoneNumber)
        .get();

      return !snapshot.empty;
    } catch (e) {
      console.log(e);
    }
  };

  checkUserAndGetData = async (phoneNumber) => {
    try {
      let snapshot = await this.db
        .collection("users")
        .where("phoneNumber", "==", phoneNumber)
        .limit(1)
        .get();

      let data;
      snapshot.docs.map((doc) => {
        if (doc.exists) {
          data = doc.data();
        }
      });

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  getUsers = () => {
    return this.db
      .collection("users")
      .limit(10)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.id, "=> ", doc.data());
        });
      });
  };

  getPendingUser = async () => {
    try {
      let snapshot = await this.db.collection("pending").get();
      let pendings = [];
      snapshot.forEach((doc) => {
        pendings.push({ ...doc.data(), id: doc.id });
      });

      return pendings;
    } catch (e) {
      console.log(e);
    }
  };

  deletePendingUser = async (id) => {
    try {
      const result = await this.db.collection("pending").doc(id).delete();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  approvePendingUser = async (user) => {
    console.log(user);
    try {
      // check if user already exists in user database;
      if (!(await this.checkUserExists(user.phoneNum))) {
        await this.db.collection("users").add({
          adaNo: user.aboNum,
          email: user.email,
          enable: true,
          fullName: user.fullName,
          location: user.centerLocation,
          phoneNumber: user.phoneNum,
        });

        await this.deletePendingUser(user.id);

        return true;
      } else {
        return false;
      }

      // TODO: check to see if similar email has been registered, if yes notify admin
      // TODO: send SMS to user to notify user, account has been approved
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  getEvents = async () => {
    let snapshot = await this.db.collection("events").get();
    let events = [];

    snapshot.forEach((doc) => {
      let event = doc.data();
      events.push({
        id: doc.id,
        title: event.title,
        start: moment(event.startTime).toDate(),
        end: moment(event.endTime).toDate(),
        tags: event.tags,
        location: event.location,
        notes: event.desc,
      });
    });

    return events;
  };

  addEvent = async (event) => {
    try {
      let fire_event = await this.db.collection("events").add({
        title: event.title,
        startTime: event.startTime,
        endTime: event.endTime,
        tags: event.tags,
        location: event.location,
        desc: event.notes,
        type: 3,
        complete: false,
        dateCreated: Date.now(),
      });

      return {
        id: fire_event.id,
        title: event.title,
        start: moment(event.startTime).toDate(),
        end: moment(event.endTime).toDate(),
        tags: event.tags,
        location: event.location,
        notes: event.notes,
      };
    } catch (e) {
      throw e;
    }
  };

  updateEvent = async (id, event) => {
    try {
      await this.db.collection("events").doc(id).update({
        title: event.title,
        startTime: event.startTime,
        endTime: event.endTime,
        tags: event.tags,
        location: event.location,
        desc: event.notes,
      });

      return {
        id: id,
        title: event.title,
        start: moment(event.startTime).toDate(),
        end: moment(event.endTime).toDate(),
        tags: event.tags,
        location: event.location,
        notes: event.notes,
      };
    } catch (e) {
      throw e;
    }
  };

  deleteEvent = async (id) => {
    try {
      await this.db.collection("events").doc(id).delete();
    } catch (e) {
      throw e;
    }
  };

  getMedias = async () => {
    let snapshot = await this.db.collection("medias").get();
    let medias = [];

    snapshot.forEach((doc) => {
      let media = doc.data();

      if (media.type === 0) {
        medias.push({
          ...media,
          id: doc.id,
          startTime: moment(media.startTime).toDate(),
          endTime: moment(media.endTime).toDate(),
        });
      } else {
        medias.push({
          ...media,
          id: doc.id,
        });
      }
    });
    return medias;
  };

  uploadImage = async (name, file) => {
    try {
      let mediaRef = await this.storage.ref().child(`medias/${name}.jpg`);
      let uploadTask = await mediaRef.put(file);
      return mediaRef.getDownloadURL();
    } catch (e) {
      console.log(e);
    }
  };

  addMedia = async ({ type, pos, media }) => {
    try {
      if (type == 0) {
        let imageURL = await this.uploadImage(
          `news-${media.title}`,
          media.files[0]
        );
        let fire_media = await this.db.collection("medias").add({
          desc: media.desc,
          startTime: media.startTime,
          endTime: media.endTime,
          imageURL,
          personInCharge: media.personInCharge,
          phoneNumber: media.phoneNumber,
          title: media.title,
          type,
          venue: media.venue,
        });

        return {
          id: fire_media.id,
          type,
          desc: media.desc,
          startTime: moment(media.startTime).toDate(),
          endTime: moment(media.endTime).toDate(),
          imageURL,
          personInCharge: media.personInCharge,
          phoneNumber: media.phoneNumber,
          title: media.title,
          venue: media.venue,
        };
      } else {
        let imageURL = await this.uploadImage(
          `media${pos + 1}`,
          media.files[0]
        );
        let fire_media = await this.db.collection("medias").add({
          type,
          pos,
          linkTo: media.mediaLink,
          imageURL,
        });

        return {
          id: fire_media.id,
          type,
          pos,
          linkTo: media.mediaLink,
          imageURL,
        };
      }
    } catch (e) {
      throw e;
    }
  };

  updateMedia = async (id, media) => {
    console.log(media);

    try {
      if (media.type == 0) {
        await this.db.collection("medias").doc(id).update({
          desc: media.desc,
          startTime: media.startTime,
          endTime: media.endTime,
          imageURL: media.imageURL,
          personInCharge: media.personInCharge,
          phoneNumber: media.phoneNumber,
          title: media.title,
          type: media.type,
          venue: media.venue,
        });

        return {
          id: id,
          desc: media.desc,
          startTime: moment(media.startTime).toDate(),
          endTime: moment(media.endTime).toDate(),
          imageURL: media.imageURL,
          personInCharge: media.personInCharge,
          phoneNumber: media.phoneNumber,
          title: media.title,
          type: media.type,
          venue: media.venue,
        };
      } else {
        await this.db.collection("medias").doc(id).update({
          type: media.type,
          pos: media.pos,
          linkTo: media.mediaLink,
          imageURL: media.imageURL,
        });

        return {
          id: id,
          type: media.type,
          pos: media.pos,
          linkTo: media.mediaLink,
          imageURL: media.imageURL,
        };
      }
    } catch (e) {
      throw e;
    }
  };

  deleteMedia = async (id) => {
    try {
      await this.db.collection("medias").doc(id).delete();
    } catch (e) {
      throw e;
    }
  };

  uploadReceipt = async (file) => {};
}

export { FirebaseContext };
export default Firebase;
