import React, { useContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

// Pages
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Payment from "./pages/Payment";
import Login from "./pages/Login";
import Media from "./pages/Media";
import Events from "./pages/Events";
import Renew from "./pages/Renew";

import { FirebaseContext } from "./components/Firebase";
import AuthUserContext from "./contexts/AuthUserContext";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <AuthUserContext firebase={useContext(FirebaseContext)}>
        <ScrollToTop />
        <div className="App">
          <Switch>
            <Route exact path="/">
              <Register />
            </Route>
            <Route path="/payment">
              <Payment />
            </Route>

            <Route path="/renew">
              <Renew />
            </Route>
            <Route path="/clogin">
              <Login />
            </Route>
            <ProtectedRoute adminOnly={true} path="/dashboard">
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute adminOnly={true} path="/media">
              <Media />
            </ProtectedRoute>
            <ProtectedRoute adminOnly={true} path="/events">
              <Events />
            </ProtectedRoute>
            {/* <ProtectedRoute adminOnly={false} path="/renew">
              <Renew />
            </ProtectedRoute> */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </AuthUserContext>
    </Router>
  );
}

export default App;
