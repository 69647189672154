import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { FirebaseContext } from "../../components/Firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Card from "../../components/Card";
import Table from "../../components/Table";

const table = {
  headers: [
    { className: "date", value: "Date" },
    { className: "fullName", value: "FullName" },
    { className: "aboNum", value: "ABO Number" },
    { className: "phoneNum", value: "Phone Number" },
    { className: "centerLocation", value: "Center Location" },
    { className: "upline", value: "Upline DD" },
    { className: "platform", value: "Platform" },
    { className: "status", value: "Status" },
    { className: "description", value: "Description" },
    { className: "actions", value: "Actions" },
  ],
};

export default function Dashboard() {
  let [showNavbar, setShowNavbar] = useState(false);
  let firebase = useContext(FirebaseContext);
  let [pendings, setPendings] = useState([]);

  let [values, loading, error] = useCollectionData(
    firebase.db.collection("pending"),
    {
      idField: "id",
    }
  );

  console.log(values);

  useEffect(() => {
    setPendings(values || []);
  }, [values]);

  function handleClick() {
    setShowNavbar(!showNavbar);
  }

  return (
    <div className={styles["dashboard-page"]}>
      <div className={styles["container"]}>
        <main>
          <Navbar onClick={handleClick} />
          <Sidebar show={showNavbar} onClick={handleClick} />

          <div className={styles["header"]}>
            <div className={styles["header-group"]}>
              <h1>Manage Users</h1>
              <p>Approve verified user for EVA</p>
            </div>
          </div>

          <div className={styles["content"]}>
            <Card title="Pending Users">
              <Table headers={table.headers} data={pendings} />
            </Card>
          </div>
        </main>
      </div>
    </div>
  );
}
