import React, { useRef } from "react";
import styles from "./styles.module.css";
import { Link, NavLink } from "react-router-dom";

//import hooks
import useOutsideClick from "../../hooks/useOutsideClick";

export default function Sidebar({ show, onClick }) {
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) {
      onClick();
    }
  });

  return (
    <div
      className={
        styles["sidebar-wrapper"] +
        " " +
        (show ? styles["modal-bg"] : styles["no-pointer"])
      }
    >
      <div
        className={styles["sidebar"] + " " + (show ? "" : styles["hide"])}
        ref={ref}
      >
        <div className={styles["sidebar-group"]}>
          <ul>
            <li className={styles["sub-header"]}>Actions</li>
            <li className={styles["sidebar-links"]}>
              <NavLink
                to="/dashboard"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#0061f2",
                }}
              >
                Manage Users
              </NavLink>
            </li>
            <li className={styles["sidebar-links"]}>
              <NavLink
                to="/media"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#0061f2",
                }}
              >
                Manage Medias
              </NavLink>
            </li>
            <li className={styles["sidebar-links"]}>
              <NavLink
                to="/events"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#0061f2",
                }}
              >
                Manage Events
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
