import React from 'react';
import styles from './styles.module.css';

export default function Card({ title, children }) {
    return (
        <div className={styles["card"]}>
            <div className={styles["card-header"]}>
                <h2>{title}</h2>
            </div>
            <div className={styles["card-body"]}>
                <div className={styles["overflow-div"]}>
                    {children}
                </div>
            </div>
        </div>
    );
}