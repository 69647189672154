import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import Modal from '../Modal';
import Select from 'react-select';
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('en')
momentLocalizer()

const tagOptions = [
    { value: 'team', label: 'Team' },
    { value: 'ecvi-center', label: 'Ecvi Center' },
    { value: 'ecvi-federal', label: 'Ecvi Federal' },
    { value: 'amway-malaysia', label: 'Amway Malaysia' },
]

const locationOptions = [
    { value: 'federal', label: 'Federal' },
    { value: 'alor-setar', label: 'Alor Setar' },
    { value: 'ampang', label: 'Ampang' },
    { value: 'bangi', label: 'Bangi' },
    { value: 'bintulu', label: 'Bintulu' },
    { value: 'brunei', label: 'Brunei' },
    { value: 'butterworth', label: 'Butterworth' },
    { value: 'damansara', label: 'Damansara' },
    { value: 'ipoh', label: 'Ipoh' },
    { value: 'johor-bahru', label: 'Johor Bahru' },
    { value: 'kota-kinabalu', label: 'Kota Kinabalu' },
    { value: 'kuantan', label: 'Kuantan' },
    { value: 'melaka', label: 'Melaka' },
    { value: 'miri', label: 'Miri' },
    { value: 'penang', label: 'Penang' },
    { value: 'sandakan', label: 'Sandakan' },
    { value: 'sg-petani', label: 'Sg.Petani' },
    { value: 'singapore', label: 'Singapore' },
    { value: 'tawau', label: 'Tawau' },
];

const customStyles = {
    control: (provided, state) => {
        const borderStyle = state.isFocused ? '1px solid rgba(104,109,224)' : 'none';
        const boxShadowStyle = state.isFocused ? '0px 0px 0px 4px rgba(104,109,224,0.2);' : 'none';

        return {
            ...provided,
            borderRadius: '6px',
            backgroundColor: '#f1f2f6',
            border: 'none',
            border: borderStyle,
            boxShadow: boxShadowStyle
        }
    },
}

export default function EventModal({ event, show, onShow, onEdit, onSubmit, onDelete }) {

    useEffect(() => {
        if (!event['title']) {
            resetState();
            setStartTime(event['start']);
            setEndTime(event['end'])
            setIsNew(true);
        } else {
            resetState();
            setTitle(event['title']);
            setStartTime(event['start']);
            setEndTime(event['end']);
            setTags(event.tags.map((tag) => {
                return getOptionFromLabel(tagOptions, tag);
            }));
            setCenterLocation(getOptionFromLabel(locationOptions, event.location));
            setNotes(event['notes']);
            setIsNew(false);
        }

    }, [event]);

    // state for eventmodal 
    const [isNew, setIsNew] = useState(true);
    const [title, setTitle] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [tags, setTags] = useState([]);
    const [centerLocation, setCenterLocation] = useState('');
    const [notes, setNotes] = useState('');

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handleStartTimeChange = (e) => {
        setStartTime(e);
    }

    const handleEndTimeChange = (e) => {
        setEndTime(e);
    }

    const handleTagsChange = (selectedOption) => {
        if (selectedOption) {
            let tags = selectedOption.map((tag) => {
                return tag;
            });
            setTags(tags);
        } else {
            setTags([]);
        }
    }

    const handleLocationChange = (selectedOption) => {
        setCenterLocation(selectedOption);
    }

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    }

    const handleOutsideClick = () => {

        let result = window.confirm("Are you sure you want to discard the changes?");
        if (result) {
            resetState();
            onShow();
        }
    }

    const getOptionFromLabel = (options, label) => {
        let index = options.findIndex(option => option.label == label);

        return options[index];
    }

    const resetState = () => {
        setTitle('');
        setCenterLocation('');
        setTags([]);
        setStartTime('');
        setEndTime('');
        setNotes('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(e.target);

        try {
            if (isNew) {
                await onSubmit({
                    title,
                    startTime,
                    endTime,
                    tags,
                    centerLocation,
                    notes
                });

            } else {
                await onEdit(event.id, {
                    title,
                    startTime,
                    endTime,
                    tags,
                    centerLocation,
                    notes
                });
            }
        } catch (e) {
            console.log(e);
        }

        onShow();

    }

    const handleShow = () => {

        let result = window.confirm("Are you sure you want to discard the changes?");
        if (result) {
            onShow();
        }
    }

    const handleDelete = async () => {
        let result = window.confirm("Are you sure you want to delete this event?");
        if (result) {
            await onDelete(event);
            onShow();
        }
    }

    return <Modal show={show} onShow={handleShow} onOutsideClick={handleOutsideClick}>
        <form className={styles["add-event-form"]} onSubmit={handleSubmit}>
            <h1 className={styles['form-header']}>{isNew ? 'Add Event' : 'Edit Event'}</h1>
            <div className={styles["form-row"]}>
                <label htmlFor="event-title">Event Title</label>
                <input id="event-title" name="event-title" type="text" className={styles["event-form-input"]} value={title} onChange={handleTitleChange} required></input>
            </div>
            <div className={styles["form-row"]}>
                <label htmlFor="event-start-time">Start Time</label>
                <DateTimePicker value={startTime} onChange={handleStartTimeChange} />
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={startTime}
                    onChange={() => { }}
                    required
                />
            </div>
            <div className={styles["form-row"]}>
                <label htmlFor="event-end-time">End Time</label>
                <DateTimePicker value={endTime} onChange={handleEndTimeChange} />
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={endTime}
                    onChange={() => { }}
                    required
                />
            </div>

            <div className={styles["form-row"]}>
                <label htmlFor="location">Tags</label>
                <>
                    <Select isMulti styles={customStyles} options={tagOptions} value={tags} onChange={handleTagsChange} />
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={tags ? tags : []}
                        onChange={() => { }}
                        required
                    />
                </>

            </div>

            <div className={styles["form-row"]}>
                <label htmlFor="location">Center Location</label>
                <>
                    <Select styles={customStyles} options={locationOptions} value={centerLocation} onChange={handleLocationChange} />
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={centerLocation}
                        onChange={() => { }}
                        required
                    />
                </>

            </div>

            <div className={styles["form-row"]}>
                <label htmlFor="event-notes">Notes</label>
                <textarea id="event-notes" name="event-notes" className={styles["event-form-textarea"]}
                    onChange={handleNotesChange}
                    value={notes}>
                </textarea>
            </div>

            <div className={styles["button-container"]}>
                <button className={styles["submit-btn"]}>{isNew ? 'Add Event' : 'Edit Event'}</button>
                {isNew ? <></> : <button type="button" className={styles["delete-btn"]} onClick={() => handleDelete(event)}>Delete Event</button>}
            </div>


        </form>
    </Modal>
}