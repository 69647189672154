import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Modal from "../Modal";
import Select from "react-select";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { DateTimePicker } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import Dropzone from "../../components/Dropzone";
import urlRegex from "url-regex";

moment.locale("en");
momentLocalizer();

export default function MediaModal({
  type,
  media,
  show,
  onShow,
  onSubmit,
  onEdit,
  onDelete,
}) {
  useEffect(() => {
    console.log(media);
    resetState();
    if (media["imageURL"]) {
      setIsNew(false);

      if (type == 0) {
        setTitle(media["title"]);
        setStartTime(media["startTime"]);
        setEndTime(media["endTime"]);
        setDescription(media["desc"]);
        setVenue(media["venue"]);
        setPersonInCharge(media["personInCharge"]);
        setPersonInChargeContact(media["phoneNumber"]);
        setMediaLink(media["linkTo"]);
        setImageURL(media["imageURL"]);
      } else {
        setMediaLink(media["linkTo"]);
        setImageURL(media["imageURL"]);
        setPos(media["pos"]);

        setValidURL(
          urlRegex({ exact: true, strict: true }).test(media["linkTo"])
        );
      }
    } else {
      setIsNew(true);
    }
  }, [media]);

  // state for eventmodal
  const [isNew, setIsNew] = useState(true);
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState(moment().toDate());
  const [endTime, setEndTime] = useState(moment().toDate());
  const [venue, setVenue] = useState("");
  const [personInCharge, setPersonInCharge] = useState("");
  const [personInChargeContact, setPersonInChargeContact] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [files, setFiles] = useState([]);
  const [validURL, setValidURL] = useState(false);
  const [pos, setPos] = useState(0);

  const handleSetFile = (files) => {
    setFiles(files);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e);
  };

  const handleVenueChange = (e) => {
    setVenue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePersonInChargeChange = (e) => {
    setPersonInCharge(e.target.value);
  };

  const handlePersonInChargeContactChange = (e) => {
    setPersonInChargeContact(e.target.value);
  };

  const handleMediaLinkChange = (e) => {
    setValidURL(urlRegex({ exact: true, strict: true }).test(e.target.value));

    setMediaLink(e.target.value);
  };

  const handleOutsideClick = () => {
    let result = window.confirm(
      "Are you sure you want to discard the changes?"
    );
    if (result) {
      resetState();
      onShow();
    }
  };

  const resetState = () => {
    setTitle("");
    setVenue("");
    setStartTime(moment().toDate());
    setEndTime(moment().toDate());
    setDescription("");
    setPersonInCharge("");
    setPersonInChargeContact("");
    setFiles([]);
    setImageURL("");
    setMediaLink("");
    setValidURL(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log({
      title,
      startTime,
      endTime,
      venue,
      personInCharge,
      personInChargeContact,
      description,
      mediaLink,
      files,
    });
    try {
      if (isNew) {
        await onSubmit({
          type,
          title,
          startTime,
          endTime,
          venue,
          personInCharge,
          personInChargeContact,
          description,
          mediaLink,
          files,
        });
      } else {
        await onEdit(media.id, {
          type,
          title,
          startTime,
          endTime,
          venue,
          personInCharge,
          personInChargeContact,
          description,
          mediaLink,
          files,
          imageURL,
          pos,
        });
      }
    } catch (e) {
      console.log(e);
    }

    onShow();
  };

  const handleShow = () => {
    let result = window.confirm(
      "Are you sure you want to discard the changes?"
    );
    if (result) {
      onShow();
    }
  };

  const handleDelete = async () => {
    let result = window.confirm("Are you sure you want to delete this event?");
    if (result) {
      await onDelete(media);
      onShow();
    }
  };

  const mediaForm = (type) => {
    if (type == 0) {
      return (
        <>
          <h1 className={styles["form-header"]}>
            {isNew ? "Add News" : "Edit News"}
          </h1>
          <div className={styles["form-row"]}>
            <label htmlFor="media-title">News Title</label>
            <input
              id="media-title"
              name="media-title"
              type="text"
              className={styles["media-form-input"]}
              value={title}
              onChange={handleTitleChange}
              required
            ></input>
          </div>

          <div className={styles["form-row"]}>
            <label>Cover Photo</label>
            <Dropzone onSetFile={handleSetFile} />
            {!imageURL ? (
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={files}
                onChange={() => {}}
                required
              />
            ) : (
              <></>
            )}
          </div>

          {imageURL && files.length == 0 ? (
            <div className={styles["form-row"]}>
              <div className={styles["img-preview"]}>
                <img src={imageURL} />
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={styles["form-row"]}>
            <label htmlFor="media-start-time">Start Time</label>
            <DateTimePicker
              value={startTime}
              onChange={handleStartTimeChange}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={startTime}
              onChange={() => {}}
              required
            />
          </div>
          <div className={styles["form-row"]}>
            <label htmlFor="media-end-time">End Time</label>
            <DateTimePicker value={endTime} onChange={handleEndTimeChange} />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={endTime}
              onChange={() => {}}
              required
            />
          </div>

          <div className={styles["form-row"]}>
            <label htmlFor="media-venue">Venue</label>
            <input
              id="media-venue"
              name="media-venue"
              type="text"
              className={styles["media-form-input"]}
              value={venue}
              onChange={handleVenueChange}
              required
            ></input>
          </div>

          <div className={styles["form-row"]}>
            <label htmlFor="media-pic">Person In Charge Name</label>
            <input
              id="media-pic"
              name="media-pic"
              type="text"
              className={styles["media-form-input"]}
              value={personInCharge}
              onChange={handlePersonInChargeChange}
              required
            ></input>
          </div>

          <div className={styles["form-row"]}>
            <label htmlFor="media-picContact">
              Person In Charge Whatsapp Number
            </label>
            <input
              id="media-picContact"
              name="media-picContact"
              type="tel"
              className={styles["media-form-input"]}
              value={personInChargeContact}
              onChange={handlePersonInChargeContactChange}
              required
              placeholder="+60123456789 (Include Internation Code)"
            ></input>
          </div>

          <div className={styles["form-row"]}>
            <label htmlFor="media-description">Description</label>
            <textarea
              id="media-description"
              name="media-description"
              className={styles["media-form-textarea"]}
              onChange={handleDescriptionChange}
              value={description}
            ></textarea>
          </div>

          <div className={styles["button-container"]}>
            <button className={styles["submit-btn"]}>
              {isNew ? "Add News" : "Edit News"}
            </button>
            {isNew ? (
              <></>
            ) : (
              <button
                type="button"
                className={styles["delete-btn"]}
                onClick={() => handleDelete(media)}
              >
                Delete News
              </button>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1 className={styles["form-header"]}>
            {isNew ? "Add Media" : "Edit Media"}
          </h1>

          <div className={styles["form-row"]}>
            <label>Cover Photo</label>
            <Dropzone onSetFile={handleSetFile} />
            {!imageURL ? (
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={files}
                onChange={() => {}}
                required
              />
            ) : (
              <></>
            )}
          </div>

          {imageURL && files.length == 0 ? (
            <div className={styles["form-row"]}>
              <div className={styles["img-preview"]}>
                <img src={imageURL} />
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={styles["form-row"]}>
            <label htmlFor="media-title">Media Website Link</label>
            <input
              id="media-title"
              name="media-title"
              type="text"
              className={styles["media-form-input"]}
              value={mediaLink}
              onChange={handleMediaLinkChange}
              required
            ></input>
            {!validURL ? (
              <p className={styles["error-msg"]}>Please enter a valid URL</p>
            ) : (
              <></>
            )}
          </div>

          <div className={styles["button-container"]}>
            <button className={styles["submit-btn"]} disabled={!validURL}>
              {isNew ? "Add Media" : "Edit Media"}
            </button>
            {isNew ? (
              <></>
            ) : (
              <button
                type="button"
                className={styles["delete-btn"]}
                onClick={() => handleDelete(media)}
              >
                Delete Media
              </button>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <Modal show={show} onShow={handleShow} onOutsideClick={handleOutsideClick}>
      <form className={styles["add-media-form"]} onSubmit={handleSubmit}>
        {mediaForm(type)}
      </form>
    </Modal>
  );
}
