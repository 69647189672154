import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { FirebaseContext } from "../../components/Firebase";
import EventCalendar from "../../components/EventCalendar";
import Card from "../../components/Card";
import EventModal from "../../components/EventModal";
import Select from "react-select";
import { DateTimePicker } from "react-widgets";
import _Navbar from "../../components/Navbar";
import moment from "moment";

export default function Events() {
  let firebase = useContext(FirebaseContext);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});

  useEffect(() => {
    async function get() {
      let events = await fetchEvents();
      setEvents(events);
    }

    get();
  }, []);

  function handleClick() {
    setShowNavbar(!showNavbar);
  }

  async function fetchEvents() {
    try {
      let events = await firebase.getEvents();
      return events;
    } catch (e) {
      console.log(e);
    }
  }

  const handleShow = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleSelectSlot = ({ start, end }) => {
    setEvent({
      start,
      end,
    });
    setShowModal(true);
  };

  const handleRangeChange = (e) => {
    console.log(e);
  };

  const handleNavigation = (e) => {
    console.log(moment(e).month());
  };

  const handleSelectEvent = (event) => {
    setEvent(event);
    setShowModal(true);
  };

  const handleDelete = async (e) => {
    await firebase.deleteEvent(e.id);

    let _events = events.filter((event) => event.id != e.id);
    setEvents(_events);
  };

  const handleEdit = async (id, event) => {
    console.log(event);

    let _event = await firebase.updateEvent(id, {
      title: event.title,
      startTime: moment(event.startTime).valueOf(),
      endTime: moment(event.endTime).valueOf(),
      notes: event.notes,
      location: event.centerLocation.label,
      tags: event.tags.map((tag) => tag.label),
    });

    let _events = events.filter((e) => e.id != _event.id);

    console.log(_events);
    setEvents([..._events, _event]);
  };

  const handleSubmit = async (e) => {
    if (
      e.title != "" &&
      e.startTime != "" &&
      e.endTime != "" &&
      e.tags.length != 0 &&
      e.centerLocation != ""
    ) {
      let event = await firebase.addEvent({
        title: e.title,
        startTime: moment(e.startTime).valueOf(),
        endTime: moment(e.endTime).valueOf(),
        notes: e.notes,
        location: e.centerLocation.label,
        tags: e.tags.map((tag) => tag.label),
      });

      setEvents([...events, event]);
    }
  };

  return (
    <div className={styles["events-page"]}>
      <div className={styles["container"]}>
        <Navbar onClick={handleClick} />
        <Sidebar show={showNavbar} onClick={handleClick} />

        <div className={styles["header"]}>
          <div className={styles["header-group"]}>
            <h1>Manage Events</h1>
            <p>Add, Update or Delete Events for EVA</p>
          </div>
        </div>

        <div className={styles["content"]}>
          <div className={styles["event-calendar"]}>
            <Card title="Events">
              <EventCalendar
                events={events}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onRangeChange={handleRangeChange}
                onNavigate={handleNavigation}
              />
            </Card>
          </div>
        </div>
        <EventModal
          event={event}
          show={showModal}
          onShow={handleShow}
          onEdit={handleEdit}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
}
