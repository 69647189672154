import React, { useContext } from 'react';
import styles from './styles.module.css';
import MenuIcon from '../../icons/HamburgerMenu';
import { FirebaseContext } from '../Firebase';

export default function _Navbar({ onClick }) {

    let firebase = useContext(FirebaseContext);

    async function handleLogout() {
        await firebase.logout();
        // push to login page
    }

    return <nav className={styles['navbar']}>
        <div className={styles["navbar-group"]}>
            <button className={styles["menu-button"]} onClick={onClick}><MenuIcon /></button>
            <button className={styles["logout-button"]} onClick={handleLogout}>Logout</button>
        </div>
    </nav>
}

