import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import FadeIn from "react-fade-in";
import Close from "../../icons/Close";
import { motion, AnimatePresence } from "framer-motion";

//import hooks
import useOutsideClick from "../../hooks/useOutsideClick";

export default function Modal({ show, onShow, onOutsideClick, children }) {
  const modalRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const handleClick = (e) => {
    if (modalRef.current == e.target) {
      onOutsideClick();
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={styles["modal-container"]}
          ref={modalRef}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <motion.div
            className={styles["modal"]}
            initial={{
              y: "100px",
            }}
            animate={{
              y: "0",
            }}
            exit={{
              y: "100px",
            }}
            transition={{
              type: "tween",
              duration: 0.2,
            }}
          >
            <button className={styles["close-button"]} onClick={onShow}>
              <Close />
            </button>
            <div className={styles["modal-content"]}>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
