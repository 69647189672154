import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../../components/Firebase";
// import Loading from '../../components/Loading';
import styles from "./styles.module.css";
// import AuthUserContext from '../../contexts/AuthUserContext';

export default function Login() {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    firebase.logout();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await firebase.login(email, password);
      setLoading(false);
      history.push("/dashboard");
    } catch (e) {
      setLoading(false);
      setError(e);

      setTimeout(() => {
        setError(null);
      }, 500);
    }
  };

  return (
    <div className={styles["login-page"]}>
      <div className={styles["container"]}>
        <form
          className={
            styles["login-form"] + " " + (error ? styles["toggle-left"] : "")
          }
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange}
            className={styles["login-input"]}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            className={styles["login-input"]}
            required
          />
          <button
            className={`${styles["submit-btn"]} ${
              loading ? styles["disabled-btn"] : ""
            }`}
            type="submit"
            disabled={loading}
          >
            {!loading ? "Login" : "Loading..."}
          </button>
        </form>
      </div>
    </div>
  );
}
