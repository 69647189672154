import React, { useEffect, useState, useContext } from "react";

export const AuthUserContext = React.createContext({});

export default ({ firebase, children }) => {
  // const [currentUser, setCurrentUser] = useState(null);

  const prevAuth = JSON.parse(localStorage.getItem("auth"));
  const prevAuthBody = JSON.parse(localStorage.getItem("authBody"));
  const prevIsAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [authBody, setAuthBody] = useState(prevAuthBody);
  const [isAdmin, setIsAdmin] = useState(prevIsAdmin);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // Only save for admin's login, do user login in UserLogin component
        if (checkForAdmin(authUser.uid)) {
          console.log("This is admin");
          setAuthenticated(true);
          setAuthBody(authUser);
          setIsAdmin(true);

          localStorage.setItem("auth", true);
          localStorage.setItem("authBody", JSON.stringify(authBody));
          localStorage.setItem("isAdmin", true);
        }
      } else {
        console.log("Not login");
        setAuthenticated(null);
        setAuthBody(null);
        setIsAdmin(null);

        localStorage.setItem("auth", null);
        localStorage.setItem("authBody", null);
        localStorage.setItem("isAdmin", null);
      }
    });
  }, [authenticated, authBody, isAdmin]);

  function checkForAdmin(id = "") {
    let admins = ["058vTkrhTeOstfaPUDVK3155LSs1"];

    return admins.includes(id);
  }

  const defaultContext = {
    authenticated,
    setAuthenticated,
    authBody,
    setAuthBody,
    isAdmin,
    setIsAdmin,
  };

  console.log(defaultContext);

  return (
    <AuthUserContext.Provider value={defaultContext}>
      {children}
    </AuthUserContext.Provider>
  );
};
