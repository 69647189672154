import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import styles from "./styles.module.css";
import FadeIn from "react-fade-in";
import Loading from "../../components/Loading";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

export default function Payment() {
  const [id, setId] = useState("");
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bill, setBill] = useState({});

  const location = useLocation();
  const history = useHistory();

  const query = queryString.parse(location.search);
  const billId = query["billplz[id]"];

  const isRenew = localStorage.getItem("isRenew") || false;

  useEffect(() => {
    if (billId !== localStorage.getItem("billId")) {
      history.replace("/");
    } else {
      //   // get bill from billplz api
      getBillById();

      async function getBillById() {
        let bill = await axios.get(
          `https://us-central1-ecvi-eva.cloudfunctions.net/api/get-bill/${billId}`
        );

        setBill(bill.data);
        setLoading(false);
      }
    }

    return () => {
      localStorage.removeItem("billId");
    };
  }, []);

  const PaymentPageBody = () => {
    return (
      <div className={styles["payment-page"]}>
        <div className={styles["container"]}>
          <main>
            <div className={styles["payment-header"]}>
              <img className={styles["logo"]} src="/ecvi-logo.png" />
              <h2>CVI GLOBAL BERHAD (836664P)</h2>
              <div className={styles["company-info"]}>
                <p>+60146002043</p>
                <p>ecvi2043@gmail.com</p>
              </div>
              <p className={styles["payment-status"]}>
                {isRenew
                  ? `Payment completed! Your account has been renewed successfully.`
                  : `Payment completed! You will be notified once your account is
                ready.`}
              </p>
            </div>
            <div className={styles["receipt-container"]}>
              <div className={styles["receipt"]}>
                <div className={styles["receipt-header"]}>
                  <div className={styles["paid"]}>
                    <span className={styles["bill-title"]}>Total paid: </span>
                    <span className={styles["bill-title"]}>
                      RM {bill["amount"] / 100}
                    </span>
                  </div>

                  <div className={styles["paid-on"]}>
                    <span className={styles["bill-title"]}>Paid on </span>
                    <span className={styles["bill-title"]}>
                      {moment(bill["paid_at"]).format("DD/MM/YYYY h:mm:ss a")}
                    </span>
                  </div>
                </div>
                <div className={styles["receipt-body"]}>
                  <p>
                    <span className={styles["bill-title"]}>Bill ID: </span>{" "}
                    {bill["id"]}
                  </p>
                  <p>
                    <span className={styles["bill-title"]}>Name: </span>{" "}
                    {bill["name"]}
                  </p>
                  <p>
                    <span className={styles["bill-title"]}>Email: </span>
                    {bill["email"]}
                  </p>
                  <p>
                    <span className={styles["bill-title"]}>
                      Mobile Number:{" "}
                    </span>{" "}
                    {bill["mobile"]}
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  };

  return <>{loading ? <></> : PaymentPageBody()}</>;

  return PaymentPageBody();
}
