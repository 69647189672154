import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import styles from './styles.module.css';

export default function Dropzone(props) {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useEffect(() => {
        props.onSetFile(files);
    }, [files]);

    const thumbs = files.map(file => (
        <div className={styles["thumb"]} key={file.name}>
            <div className={styles["thumbInner"]}>
                <img
                    className={styles["thumbImg"]}
                    src={file.preview}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className={styles["dropzone-container"]}>
            <div {...getRootProps({ className: styles['dropzone'] })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside className={styles["thumbsContainer"]}>
                {thumbs}
            </aside>
        </section >
    );
}

