import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../components/Firebase";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import styles from "./styles.module.css";
import axios from "axios";

const Renew = () => {
  let auth = useContext(AuthUserContext);
  let firebase = useContext(FirebaseContext);
  let history = useHistory();
  let [loading, setLoading] = useState(false);
  let [phoneNumber, setPhoneNumber] = useState("");
  let [phoneNumberError, setPhoneNumberError] = useState(null);
  let [smsCode, setSmsCode] = useState("");
  let [requestSms, setRequestSms] = useState(false);
  let [loadCaptcha, setLoadCaptcha] = useState(false);

  useEffect(() => {
    if (!loadCaptcha) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "submit-btn",
        {
          size: "invisible",
          callback: function (response) {
            verifyNumber();
          },
        }
      );

      setLoadCaptcha(true);
    }
  }, [loadCaptcha]);

  const buttonText = () => {
    let buttonText;
    if (!requestSms) {
      if (loading) {
        buttonText = "Loading...";
      } else {
        buttonText = "GET CONFIRMATION CODE";
      }
    } else {
      if (loading) {
        buttonText = "Loading...";
      } else {
        buttonText = "MAKE PAYMENT";
      }
    }

    return buttonText;
  };

  const handlePhoneNumChange = (e) => {
    let phoneNumber = e.target.value.replace(/\D/g, "");

    if (phoneNumber.length <= 9) {
      setPhoneNumberError("Phone Number is too short");
    } else {
      setPhoneNumberError(null);
    }

    setPhoneNumber(phoneNumber);
  };

  const handleSmsCodeChange = (e) => {
    setSmsCode(e.target.value);
  };

  const verifyNumber = async (e) => {
    // e.preventDefault();

    if (phoneNumber) {
      // resetState();
      setLoading(true);

      var appVerifier = window.recaptchaVerifier;
      let formattedPhoneNumber = formatPhoneNumber(phoneNumber);

      // check if number is valid
      try {
        let res = await axios.post(
          "https://us-central1-ecvi-eva.cloudfunctions.net/api/checkPhoneNumber",
          {
            phoneNumber: formattedPhoneNumber,
          }
        );

        if (res.status === 200) {
          let exists = res.data.exists;

          if (exists) {
            firebase
              .auth()
              .signInWithPhoneNumber(formattedPhoneNumber, appVerifier)
              .then(function (confirmationResult) {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setRequestSms(true);
                // setSuccess("TAC is sent to your phone.");

                // smsInput.current.focus();
              })
              .catch(function (error) {
                console.log(`VerifyNumberError: ${error}`);
                setLoading(false);
              });
          } else {
            alert("Please register");
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const loginUser = () => {
    console.log(smsCode, formatPhoneNumber(phoneNumber));

    window.confirmationResult.confirm(smsCode).then(async function (result) {
      console.log("Signing in");
      const user = result.user;
      const phoneNumber = user.phoneNumber;

      console.log(phoneNumber);

      let data = await firebase.checkUserAndGetData(phoneNumber);

      if (data) {
        auth.setIsAdmin(false);
        auth.setAuthenticated(true);

        localStorage.setItem("isAdmin", false);
        localStorage.setItem("auth", true);

        // history.push("/renew");

        let paymentRes = await axios.post(
          "https://us-central1-ecvi-eva.cloudfunctions.net/api/renew",
          {
            name: data.fullName,
            amount: 100,
            desc: "Renew EVA",
            email: data.email,
            mobile: data.phoneNumber,
          }
        );

        localStorage.setItem("billId", paymentRes.data.id);
        localStorage.setItem("isRenew", true);

        window.location.replace(
          paymentRes.status == 200 && paymentRes.data.url
        );
      } else {
        console.log("ID havent registered");
      }
    });
  };

  const buttonAvailability = () => {
    if (!requestSms) {
      if (phoneNumber.length >= 10) {
        return false;
      } else {
        return true;
      }
    } else {
      if (smsCode.length >= 6) {
        return false;
      } else {
        return true;
      }
    }
  };

  function formatPhoneNumber(phoneNumber) {
    try {
      let formattedNumber;

      let firstThreeDigit = phoneNumber.substring(0, 3);

      if (firstThreeDigit.includes("60")) {
        if (phoneNumber.length >= 10 && phoneNumber.length <= 14) {
          formattedNumber = `+${phoneNumber}`;
        } else {
          setPhoneNumberError("Please enter a valid phone number");
          throw "MY:INVALID";
        }
      } else if (firstThreeDigit.includes("65")) {
        if (phoneNumber.length >= 8 && phoneNumber.length <= 11) {
          formattedNumber = `+${phoneNumber}`;
        } else {
          setPhoneNumberError("Please enter a valid phone number");
          throw "SG:INVALID";
        }
      } else {
        if (
          phoneNumber.length <= 8 &&
          (phoneNumber.substring(0, 1).includes("3") ||
            phoneNumber.substring(0, 1).includes("6") ||
            phoneNumber.substring(0, 1).includes("8") ||
            phoneNumber.substring(0, 1).includes("9"))
        ) {
          formattedNumber = `+65${phoneNumber}`;
        } else if (
          phoneNumber.substring(0, 2).includes("01") &&
          phoneNumber.length >= 10 &&
          phoneNumber.length <= 11
        ) {
          formattedNumber = `+60${phoneNumber.substring(
            1,
            phoneNumber.length
          )}`;
        } else {
          setPhoneNumberError("Please enter a valid phone number");
          throw "INVALID";
        }
      }

      return formattedNumber;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={styles["register-page"]}>
      <div className={styles["container"]}>
        <main>
          <div className={styles["registration-form-header"]}>
            <img className={styles["logo"]} src="/ecvi-logo.png" />
            <h2>Renew your EVA</h2>
            <p>
              If you dont have an account,{" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "red",
                }}
              >
                Click here to register.
              </Link>
            </p>
          </div>

          <form action="" method="get" className={styles["registration-form"]}>
            <div className={styles["form-row"]}>
              <label htmlFor="phone-number">Phone Number</label>
              <input
                type="tel"
                name="phone-number"
                className={styles["registration-form-input"]}
                id="phone-number"
                value={phoneNumber}
                onChange={handlePhoneNumChange}
                required
                placeholder="Phone Number (0123456789)"
              ></input>
              <span
                style={{ color: "red", marginTop: "6px", fontSize: "14px" }}
              >
                {phoneNumber.length > 0 && phoneNumberError && phoneNumberError}
              </span>
            </div>

            {requestSms && (
              <div className={styles["form-row"]}>
                <label htmlFor="phone-number">SMS Code</label>
                <input
                  type="tel"
                  name="sms-code"
                  className={styles["registration-form-input"]}
                  id="sms-code"
                  value={smsCode}
                  onChange={handleSmsCodeChange}
                  required
                  placeholder="SMS Code"
                  maxLength="6"
                ></input>
              </div>
            )}

            <div className={styles["btn-row"]}>
              <button
                type="button"
                onClick={requestSms ? loginUser : verifyNumber}
                disabled={buttonAvailability()}
                id="submit-btn"
                className={`${styles["submit-btn"]}`}
              >
                {buttonText()}
              </button>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
};

export default Renew;
