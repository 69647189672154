import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment)

export default function EventCalendar({ events, onSelectEvent, onSelectSlot, onRangeChange, onNavigate }) {


    return <div>
        <Calendar
            selectable
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
            onRangeChange={onRangeChange}
            onNavigate={onNavigate}
        />
    </div>
}