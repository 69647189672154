import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { FirebaseContext } from "../../components/Firebase";
import MediaCard from "../../components/MediaCard";
import MediaModal from "../../components/MediaModal";
import ReactLoading from "react-loading";
import moment from "moment";

export default function Media() {
  let [showNavbar, setShowNavbar] = useState(false);
  let [loading, setLoading] = useState(true);
  let [medias, setMedias] = useState([]);
  let [media, setMedia] = useState({});
  let [type, setType] = useState(0);
  let [showModal, setShowModal] = useState(false);
  let firebase = useContext(FirebaseContext);

  useEffect(() => {
    getMedias();
    async function getMedias() {
      const medias = await firebase.getMedias();
      setMedias(medias);
      setLoading(false);
    }
  }, []);

  function handleClick() {
    setShowNavbar(!showNavbar);
  }

  function handleSelectMedia(media) {
    setMedia(media);
    setType(media["type"]);
    setShowModal(true);
  }

  function handleShow() {
    setShowModal((prevState) => {
      if (prevState == true) {
        setMedia({});
      }

      return !prevState;
    });
  }

  function handleAddNew(type) {
    setMedia({});
    setType(type);
    setShowModal(true);
  }

  async function handleEdit(id, _m) {
    try {
      console.log("Inside edit: ", _m);

      if (_m.type == 0) {
        let imageURL = _m.imageURL;
        if (_m.files.length > 0) {
          imageURL = await firebase.uploadImage(
            `news-${_m.title}`,
            _m.files[0]
          );
        }

        let _media = await firebase.updateMedia(id, {
          type: _m.type,
          desc: _m.description,
          personInCharge: _m.personInCharge,
          phoneNumber: _m.personInChargeContact,
          title: _m.title,
          venue: _m.venue,
          startTime: moment(_m.startTime).valueOf(),
          endTime: moment(_m.endTime).valueOf(),
          imageURL: imageURL,
        });

        console.log(_media);

        let _medias = medias.filter((m) => m.id != id);

        setMedias([..._medias, _media]);
      } else {
        let imageURL = _m.imageURL;

        if (_m.files.length > 0) {
          imageURL = await firebase.uploadImage(
            `media${_m.pos + 1}`,
            _m.files[0]
          );
        }

        console.log(_m);

        let _media = await firebase.updateMedia(id, {
          type: _m.type,
          pos: _m.pos,
          mediaLink: _m.mediaLink,
          imageURL: imageURL,
        });

        let _medias = medias.filter((m) => m.id != id);

        setMedias([..._medias, _media]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit(media) {
    if (media.type == 0) {
      let _media = {
        type: media.type,
        desc: media.description,
        files: media.files,
        personInCharge: media.personInCharge,
        phoneNumber: media.personInChargeContact,
        title: media.title,
        venue: media.venue,
        startTime: moment(media.startTime).valueOf(),
        endTime: moment(media.endTime).valueOf(),
      };

      console.log(_media);
      let _mediaItem = await firebase.addMedia({
        type: media.type,
        media: _media,
      });

      setMedias([...medias, _mediaItem]);
    } else {
      let _mediaItem = await firebase.addMedia({
        type: media.type,
        pos: mediaItem.length,
        media: media,
      });
      setMedias([...medias, _mediaItem]);
    }
  }

  async function handleDelete(e) {
    await firebase.deleteMedia(e.id);

    let _medias = medias.filter((_media) => _media.id != e.id);
    setMedias(_medias);
  }

  async function handleLogout() {
    await firebase.logout();
    // push to login page
  }

  const news = medias
    .filter((media) => media.type == 0)
    .sort((a, b) => a.startTime - b.startTime);
  const mediaItem = medias
    .filter((media) => media.type == 1)
    .sort((a, b) => a.pos - b.pos);

  return (
    <div className={styles["media-page"]}>
      <div className={styles["container"]}>
        <Navbar onClick={handleClick} onLogout={handleLogout} />
        <Sidebar show={showNavbar} onClick={handleClick} />

        <div className={styles["header"]}>
          <div className={styles["header-group"]}>
            <h1>Manage Media</h1>
            <p>Add, Update or Delete Media for EVA</p>
          </div>
        </div>
        {loading ? (
          <div className={styles["center"]}>
            <ReactLoading
              type="bubbles"
              color="#ffffff"
              height={"5%"}
              width={"5%"}
            />
          </div>
        ) : (
          <div className={styles["content"]}>
            <div className={styles["media-row"]}>
              <div className={styles["title"]}>
                <h2>News</h2>
                <button
                  className={styles["add-button"]}
                  onClick={() => handleAddNew(0)}
                >
                  Add New
                </button>
              </div>

              <div className={styles["news-grid"]}>
                {news.length > 0 ? (
                  news.map((media) => {
                    return (
                      <MediaCard
                        key={media.id}
                        media={media}
                        type={0}
                        onSelectMedia={handleSelectMedia}
                      />
                    );
                  })
                ) : (
                  <p>Please add a new item.</p>
                )}
              </div>
            </div>

            <div className={styles["media-row"]}>
              <div className={styles["title"]}>
                <h2>e-Library</h2>
                <button
                  className={styles["add-button"]}
                  onClick={() => handleAddNew(1)}
                >
                  Add New
                </button>
              </div>

              <div className={styles["media-grid"]}>
                {mediaItem.length > 0 ? (
                  mediaItem.map((media) => {
                    return (
                      <MediaCard
                        key={media.id}
                        media={media}
                        type={1}
                        onSelectMedia={handleSelectMedia}
                      />
                    );
                  })
                ) : (
                  <p>Please add a new item.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <MediaModal
        type={type}
        media={media}
        show={showModal}
        onShow={handleShow}
        onEdit={handleEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
    </div>
  );
}
